import JSONPretty from 'react-json-pretty';
import { ReactElement, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { css, cx } from '@linaria/core';

import { Bounce, Zoom } from 'react-awesome-reveal';
import { bannerWrapper, header } from '../login/login.styles';
import { Container } from '../../layout/container';
import { radial, radialBlog } from '../../app.styles';
import { tokenBox } from '../../shared/tiers-list/styles';
import { AccountField } from '../account/account-field';
import { btn, btnSmall } from '../home/buttons.styles';
import { AppContext } from '../../shared/provider';
import { useSafeState } from '../../lib/hooks';
import { ALL_PATHS, CryptoSecretKey } from '../../lib/crypto-v1';
import { DdcRecord, toDdcRecord } from '../../lib/ddc/types';
import { Loader } from '../../shared/loader';
import { appIdToTop } from '../../lib/crypto-v1/utils';

const h3Header = css`
  font-size: 2.4rem;
  line-height: 3.6rem;
`;

const table = css`
  grid-template-columns: 1fr;
  align-items: start;
`;

const recordChooser = css`
  grid-column: span 2;
`;

const jsonPretty = css`
  overflow: auto;
`;

const contentItem = css`
  max-height: 300px;
  overflow: auto;
`;

const hidden = css`
  display: none!important;
`;

const defaultRecord = null;

export function DataViewer(): ReactElement {
  const { user, eventService } = useContext(AppContext);
  const [rawData, setRawData] = useSafeState('');
  const [paths, setPaths] = useSafeState(ALL_PATHS);
  const [userPubKey, setUserPubKey] = useSafeState('');
  const [appPubKey, setAppPubKey] = useSafeState('');
  const [foundRecords, setFoundRecords] = useSafeState<DdcRecord[] | null>(defaultRecord);
  const [loadError, setLoadError] = useSafeState('');
  const [decryptError, setDecryptError] = useSafeState('');
  const [decryptedData, setDecryptedData] = useSafeState('');
  const [submitting, setSubmitting] = useSafeState(false);
  const decryptFormRef = useRef<HTMLDivElement>(null);

  const loadData = useCallback(
    (e) => {
      e.preventDefault();
      setLoadError('');
      setDecryptedData('');
      setSubmitting(true);
      setFoundRecords(defaultRecord);
      eventService
        .listEvents(appPubKey, userPubKey )
        .then((events) => {
          const lines = events
            .map(toDdcRecord)
            .filter(ddcRecord => ddcRecord != null) as DdcRecord[];
          if (lines.length > 0) {
            setFoundRecords(lines);
          } else {
            throw Error('No data found for in the DDC');
          }
        })
        .catch((err) => {
          setLoadError('The request of loading data has failed, or no data found in the DDC');
          // eslint-disable-next-line no-console
          console.error(err);
          setTimeout(setLoadError, 5000, '');
        })
        .finally(() => setSubmitting(false));
    },
    [eventService, setDecryptedData, setFoundRecords, setLoadError, setSubmitting, appPubKey, userPubKey],
  );

  useEffect(() => {
    if (user?.principal) {
      setAppPubKey(user?.principal);
    }
  }, [setAppPubKey, user?.principal]);

  const updateRawText = useCallback(
    (text) => {
      setDecryptedData('');
      setRawData(text);
    },
    [setDecryptedData, setRawData],
  );

  const onUserPubKeyChange = useCallback((value) => {
    setUserPubKey(value);
  }, [setUserPubKey]);

  const onAppPubKeyChange = useCallback((value) => {
    setAppPubKey(value);
  }, [setAppPubKey]);

  const decrypt = useCallback(
    async (e) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const jsonPaths = (formData.get('paths') as string).split(',').map((part) => part.trim());
      const masterKey = CryptoSecretKey.fromString(formData.get('encKey') as string);
      try {
        const decrypted = masterKey.decryptWithScopes(rawData, jsonPaths);
        setDecryptedData(decrypted);
      } catch (err) {
        setDecryptError("Can't decrypt data using this key");
        setTimeout(setDecryptError, 5000, '');
      }
    },
    [rawData, setDecryptedData, setDecryptError],
  );

  const handleDecrypt = (data: string) => {
    updateRawText(data);

    if (decryptFormRef && decryptFormRef.current) {
      decryptFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const isJSON = (JSONString: string) => {
    let isJson = true;
    try {
      JSON.parse(JSONString);
    } catch (e) {
      isJson = false;
    }

    return isJson;
  };

  const accountFormValid = useMemo(() => !!userPubKey && !!appPubKey, [userPubKey, appPubKey]);

  return (
    <>
      <section>
        <div className={cx(bannerWrapper, 'flex items-center')}>
          <Container>
            <div className="banner-txt">
              <h1 className={cx(header, 'font-header')}>Decentralized Data viewer</h1>
            </div>
          </Container>
        </div>
      </section>
      <section>
        <div className={cx(radial, radialBlog)} />
        <Container>
          <div className={cx(table, 'grid gap-x-10 gap-y-6 py-20')}>
            <div className={tokenBox}>
              <h3 className={h3Header}>Load data</h3>
              <p className="text-2xl text-red-300">{loadError}</p>
              <form onSubmit={loadData} className="pb-8">
                <AccountField
                  required
                  name="appPubKey"
                  label="App Public Key"
                  value={appPubKey}
                  onChange={onAppPubKeyChange}
                  readonly={!!user?.principal}
                />
                <AccountField
                  required
                  name="userPubKey"
                  label="Account Public Key"
                  value={userPubKey}
                  onChange={onUserPubKeyChange}
                />
                <button
                  disabled={submitting || !accountFormValid}
                  className={cx(btn, 'relative -left-2 mt-4 flex justify-center items-center')}
                  type="submit"
                >
                  Load {submitting && <Loader />}
                </button>
              </form>
            </div>
            {!submitting && foundRecords && foundRecords.length === 0 && (
              <div className={cx(recordChooser, 'my-4 pl-2')}>
                <h3 className="text-3xl mb-0 mt-12 text-red-300">
                  Not found any records for {'<'}
                  {userPubKey}
                  {'>'}
                </h3>
              </div>
            )}
            {foundRecords && foundRecords.length > 0 && (
              <>
                <div className={cx(recordChooser, 'my-4 pl-2')}>
                  <h3 className={h3Header}>User&apos;s Timeline</h3>

                  <div className="container max-width-lg cd-timeline__container">
                    {foundRecords.map(({ data, timestamp, id, metadata }, index) => (
                      <div className="cd-timeline__block" key={id}>
                        <Zoom className="cd-timeline__img cd-timeline__img--picture" triggerOnce>
                          <i className="icon-check-1 event-icon" />
                        </Zoom>

                        <Bounce
                          className="cd-timeline__content text-component"
                          triggerOnce
                          direction={index % 2 === 0 ? 'left' : 'right'}
                        >
                          <>
                            <p className={cx(contentItem, 'color-contrast-medium data-content')}>
                              {isJSON(data) ? (
                                <JSONPretty
                                  id="json-pretty"
                                  data={appIdToTop(JSON.parse(data))}
                                  className={jsonPretty}
                                />
                              ) : (
                                data
                              )}
                            </p>
                            <div className="flex justify-end items-center">
                              <span className="cd-timeline__date">{new Date(timestamp).toLocaleString()}</span>
                              <button
                                className={cx(btn, btnSmall, metadata?.isEncrypted ? '' : hidden)}
                                type="button"
                                onClick={handleDecrypt.bind(null, data)}
                              >
                                Decrypt
                              </button>
                            </div>
                          </>
                        </Bounce>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={cx(tokenBox)}>
                  <h3 ref={decryptFormRef} className={h3Header}>
                    Decrypt data
                  </h3>
                  <p className="text-2xl text-red-300">{decryptError}</p>
                  <form onSubmit={decrypt}>
                    <AccountField
                      required
                      readonly
                      monospaced
                      height={200}
                      name="data"
                      label="Raw data from Ddc"
                      value={rawData}
                      fieldType="textarea"
                    />
                    <AccountField
                      required
                      monospaced
                      name="paths"
                      label="JSON paths to encrypt"
                      onChange={setPaths}
                      value={paths}
                    />
                    <AccountField
                      required
                      readonly
                      monospaced
                      height={200}
                      name="decoded"
                      label="Decoded data"
                      value={decryptedData}
                      fieldType="textarea"
                    />
                    <AccountField name="encKey" label="Encryption master key" value="secret" />
                    <button disabled={submitting} className={cx(btn, 'relative -left-2 mt-4')} type="submit">
                      Decrypt
                    </button>
                  </form>
                </div>
              </>
            )}
          </div>
        </Container>
      </section>
    </>
  );
}
